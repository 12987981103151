import baseService from './baseService'

export const userService = {
  login,
  changePassword
}

function login (username, password) {
  return baseService.postRequest403Unhandled('api/auth/authenticate', { username: username, password: password })
}

function changePassword (request) {
  return baseService.patchRequest('api/auth/change-password', request)
}
