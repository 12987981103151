import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'Login',
            component: () => import(/* webpackChunkName: "about" */ '../views/login/Index')
        },
        {
            path: '/logout',
            redirect: {
                name: "Login"
            }
        },
        {
            path: '/unauthorized',
            name: 'unauthorized',
            component: () => import(/* webpackChunkName: "about" */ '../views/info/Unauthorized')
        },
        {
            path: '/invalidPage',
            name: 'invalidPage',
            component: () => import(/* webpackChunkName: "about" */ '../views/info/InvalidSession')
        },
        {
            path: '/dashboard',
            component: () => import(/* webpackChunkName: "about" */ '../views/dashboard/Index'),
            children: [
                {
                    name: 'Dashboard',
                    path: '',
                    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/Dashboard'),
                    props: { userType: 'SME' }
                },
                {
                    name: 'UserList',
                    path: '/product/:userType',
                    component: () => import(/* webpackChunkName: "UserList" */ '../views/dashboard/pages/UserList'),
                    props: { userType1: 'RETAIL' }

                },
                {
                    name: 'ChangePassword',
                    path: '/change-password',
                    component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/dashboard/pages/ChangePassword'),
                },
                {
                    name: 'ExportHistory',
                    path: '/export-history',
                    component: () => import(/* webpackChunkName: "ExportHistory" */ '../views/dashboard/pages/ExportHistory')
                },
                {
                    name: 'Learning Resources',
                    path: '/learningResources',
                    component: () => import(/* webpackChunkName: "Learning" */ '../views/dashboard/pages/LearningResources')
                }
            ]

        }
    ]
})
